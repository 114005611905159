import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
const CustomerCare = () => {
  
  
  let Patientname =useRef();
  let MobileNo=useRef();
  let Disease=useRef();
  let City=useRef();

  let form=useRef();  

  function saveFormData(e) {
    e.preventDefault();
    let allformdata={
      Patientname:"Patient Name: "+Patientname.current.value+"   "+" City : "+City.current.value,
      Mobileno:String(MobileNo.current.value),
      Disease:Disease.current.value,

    }

    // Emailjs--------------------  
    emailjs.sendForm('service_mr3c2me', 'template_rrvniru', form.current, 'aPTr3AhgArj2jD9hg')
    .then((res) => {
        console.log(res.text);
    }, (error) => {
        console.log(error.text);
    });

// Emailjs----end----------------

    console.log(allformdata,'allformdata');
    axios.post('https://ayurveda.pythonanywhere.com/ModelApi/v1/FeedbackFormViewSet/',allformdata)
    .then((res)=>{
        console.log(res,'Success Fully Saved');

        Swal.fire({
          icon:"success",
          html: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16">  <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"/>  <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M1.599 4.058a.5.5 0 0 1 .208.676A7 7 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A8 8 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208m12.802 0a.5.5 0 0 1 .676.208A8 8 0 0 1 16 8a8 8 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A7 7 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676M3.057 5.534a.5.5 0 0 1 .284.648A5 5 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A6 6 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284m9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8s-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8s-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648"/></svg>
           <h3>धन्यवाद हम, आपसे जल्द ही संपर्क करेंगे</h3>
      <h3>Thank you, for your appointment  we will contact you soon</h3>`,
          
          // text: "You clicked the button!",
        })
        popclose();

        let thankforsubmit=document.getElementById('thankforsubmit')
        thankforsubmit.style.display='block'
    })
    .catch((error)=>{
        console.log(error,'--------error-----------');
    })
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

    let oveeeer=document.getElementById('overlay')
    setTimeout(() => {
      oveeeer.style.display='none'
    }, 8000);

  }, [])

  function popclose() {
    let checkbutton=document.getElementById('modelsss')
    // console.log(checkbutton,'checkbutton');
    checkbutton.style.display='none'
    let mainSite=document.getElementById('overlay')
        mainSite.style.display='none'
  
  }
  return (
<>
<div className='' style={{marginTop:"5rem"}}>
<h1 className='text-uppercase bg-success text-white'>Contact Us</h1>
<div className=""  id="modelsss">
        <div className="" >
             <p className="" style={{fontSize:"200%"}}>Book Your <b>FREE</b> Consultation Now</p>

            {/* <button id="" onClick={popclose} className="coloe-button rounded-circle">&times;</button> */}
        </div>
        <div className="">
                <p><i>
                </i></p>
                
                  <form id="thanks" onSubmit={saveFormData} ref={form}>
                      <input type="text" name="Patientname" ref={Patientname}  placeholder="Patient name" className='form-control w-75' required /><br />  
                      <input type="number"  name="MobileNo" ref={MobileNo}  placeholder="10 Digit Mobile No" className='form-control w-75' required />  <br />
                      <input type="text" name="City" ref={City}  placeholder="City Name" className='form-control w-75' required />  <br />
                      <input type="text" name="Disease" ref={Disease}  placeholder="Disease" className='form-control w-75' required />  <br />
                      
                      {/* <button type="submit" className='btn btn-success w-75'> </button>  */}
                      <input type="submit" value="    Book   "  className='btn btn-success w-50'/>
                  </form>
                  
                <p className="msg" id="congo"> 
                    Thanks for subscribing! 
                </p> 
            
        </div>
</div>
</div>


<h1 className='' id='thankforsubmit' style={{marginTop:"7rem",display:"none"}} >Thanks For Using Services</h1>


</>
  )
}

export default CustomerCare
